import "core-js/modules/es.array.push.js";
import { ElLoading, ElMessage, ElMessageBox } from 'element-plus';
import 'element-plus/es/components/loading/style/css'
import 'element-plus/es/components/message/style/css'
import 'element-plus/es/components/message-box/style/css'

import bpmnDraw from "@/components/bpmn/bpmn-draw";
import { mapGetters } from "vuex";
import { Picture as IconPicture } from '@element-plus/icons-vue';
export default {
  name: "recordRepresentationDetail",
  components: {
    bpmnDraw,
    IconPicture
  },
  props: {
    currentId: {
      type: String
    }
  },
  computed: {
    ...mapGetters({
      userId: 'perm/getUserId'
    })
  },
  watch: {
    'currentId': function () {
      this.data = {};
      this.loadData(true);
    }
  },
  data() {
    return {
      loading: false,
      activeName: 'parkRecord',
      data: {},
      firstCheck: false,
      parkRecord: {},
      assignee: [],
      historyActivity: [],
      // 历史步骤
      toActivity: null,
      processForm: {
        modify: false,
        // 修改数据
        refundFee: 0,
        rideFee: 0,
        outFenceFee: 0,
        nonParkFee: 0,
        message: ''
      },
      processRules: {
        message: [{
          required: true,
          message: '请输入审核意见',
          trigger: 'blur'
        }]
      },
      dialogVisible: false,
      src: ''
    };
  },
  methods: {
    handleTabChange(name) {
      if (name === 'flow') {
        if (this.data.flowDefinitionId && this.data.flowInstanceId) {
          this.showFlow();
        }
      } else if (name === 'parkRecord') {
        if (!this.parkRecord.id) {
          this.loadParkRecord();
        }
      }
    },
    loadParkRecord() {
      this.$api.business.rideRecord.detail({
        id: this.data.rideRecordId,
        rideRecordTime: this.data.rideRecordTime
      }).then(res => {
        if (res.code === 200) {
          this.parkRecord = res.data;
        } else if (res.code === 121401) {
          this.$api.business.rideCurrRecord.detail({
            id: this.data.rideRecordId
          }).then(res => {
            if (res.code === 200) {
              this.parkRecord = res.data;
            }
          });
        } else {
          ElMessage.error(res.message);
        }
      });
    },
    showFlow() {
      this.loading = true;
      this.assignee = [];
      this.historyActivity = [];
      Promise.all([this.$api.flow.definition.getBpmnXml(this.data.flowDefinitionId), this.$api.flow.flowApi.processTask(this.data.flowInstanceId), this.$api.flow.flowApi.historyActivity(this.data.flowInstanceId)]).then(res => {
        if (res[0].code === 200) {
          this.$refs.bpmnDraw.show(res[0].data);
        }
        if (res[1].code === 200) {
          this.$nextTick(() => {
            let currentTaskName = [];
            let items = res[1].data;
            items.forEach(item => {
              if (item.name === '客服初审' && item.assignee === this.userId) {
                this.firstCheck = true;
                if (this.data.param) {
                  var _this$data$param$refu;
                  this.processForm.modify = true;
                  this.processForm.refundFee = (_this$data$param$refu = this.data.param.refundFee) !== null && _this$data$param$refu !== void 0 ? _this$data$param$refu : 0;
                  this.processForm.rideFee = this.data.param.rideFee;
                  this.processForm.outFenceFee = this.data.param.outFenceFee;
                  this.processForm.nonParkFee = this.data.param.nonParkFee;
                }
              }
              currentTaskName.push(item.name);
              this.$refs.bpmnDraw.marker(item.taskDefinitionKey);
              this.assignee.push(item.assignee);
            });
            if (res[2].code === 200) {
              res[2].data.forEach(item => {
                let t = this.historyActivity.filter(v => {
                  return v.activityName === item.activityName;
                });
                if ((!t || t.length === 0) && currentTaskName.indexOf(item.activityName) < 0) {
                  this.historyActivity.push(item);
                }
              });
            }
          });
        }
      }).finally(() => {
        this.loading = false;
      });
    },
    loadData(loadRecord) {
      this.loading = true;
      this.$api.business.recordRepresentation.detail({
        id: this.currentId
      }).then(res => {
        if (res.code === 200) {
          this.data = res.data;
          if (loadRecord) {
            this.loadParkRecord();
          }
        } else if (res.code === 121801) {
          this.$router.replace({
            name: '404'
          });
        } else {
          ElMessage.error(res.message);
        }
      }).finally(() => {
        this.loading = false;
      });
    },
    doProcess(type, rejectActivity) {
      this.$refs.processFormRef.validate(valid => {
        if (valid) {
          ElMessageBox.confirm('确认要进行此操作吗?', '提示', {
            type: 'warning'
          }).then(() => {
            this.loading = true;
            let param = {
              type: type,
              message: this.processForm.message,
              content: this.processForm.content,
              businessId: this.data.id
            };
            if (type === 'reject') {
              // 驳回
              param.rejectToActivityId = rejectActivity.activityId;
              param.rejectToFirst = rejectActivity.activityName === '提交资料';
            } else {
              // 通过
              if (this.firstCheck) {
                // 初审
                if (this.processForm.modify) {
                  param.variables = {
                    refundFee: this.processForm.refundFee > 0 ? this.processForm.refundFee : null,
                    rideFee: this.processForm.rideFee,
                    outFenceFee: this.processForm.outFenceFee,
                    nonParkFee: this.processForm.nonParkFee,
                    modify: true
                  };
                } else {
                  param.variables = {
                    modify: false
                  };
                }
              }
            }
            this.$api.business.recordRepresentation.flowAudit(param).then(res => {
              if (res.code === 200) {
                this.$emit('auditComplete');
              } else {
                ElMessage.error(res.message);
              }
            }).finally(() => {
              this.loading = false;
            });
          });
        }
      });
    },
    getImage(id, parkRecordTime) {
      const loading = ElLoading.service();
      this.$api.business.rideRecord.getPhotoPath({
        id,
        parkRecordTime
      }).then(res => {
        if (res.code === 200) {
          if (res.data) {
            this.dialogVisible = true;
            this.src = res.data;
          } else {
            ElMessage.error("暂无图片");
          }
        } else {
          ElMessage.error(res.message);
        }
      }).finally(() => {
        loading.close();
      });
    }
  },
  beforeMount() {
    this.loadData(true);
  }
};