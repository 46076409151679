import BpmnViewer from 'bpmn-js';
import customTranslate from './customTranslate';
export default {
  name: "bpmn-draw",
  data() {
    return {
      bpmnViewer: null
    };
  },
  methods: {
    show(flowXml) {
      this.bpmnViewer.clear();
      this.bpmnViewer.importXML(flowXml).then(result => {
        const {
          warnings
        } = result;
        console.log('success !', warnings);
        this.bpmnViewer.get('canvas').zoom('fit-viewport');
      }).catch(err => {
        const {
          warnings,
          message
        } = err;
        console.log('something went wrong:', warnings, message);
      });
    },
    marker(elId) {
      this.bpmnViewer.get('canvas').addMarker(elId, 'highlight');
    }
  },
  mounted() {
    this.bpmnViewer = new BpmnViewer({
      container: this.$refs.flowCanvas,
      additionalModules: [{
        translate: ['value', customTranslate]
      }]
    });
  },
  beforeUnmount() {
    this.bpmnViewer.destroy();
  }
};